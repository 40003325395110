import "./index.css";

import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import AboutMePage from "@/pages/AboutMe";
import App from "./App";
import HelmetWrapper from "@/components/helmetWrapper";
import PhotographyPage from "@/pages/PhotographyPage";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <App />
      </>
    ),
    //errorElement: <ErrorPage />,
    children: [
      {
        path: "/aboutMe",
        element: (
          <HelmetWrapper title="About Me">
            <AboutMePage />
          </HelmetWrapper>
        ),
      },
      {
        path: "/photography",
        element: (
          <HelmetWrapper title="photography">
            <PhotographyPage />
          </HelmetWrapper>
        ),
      },
      {
        path: "",
        element: <Navigate to="/photography" />,
      },
    ],
  },
]);

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
}

import { Button, Select } from "antd";
import { ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";
import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";

import { Image } from "antd";
import _ from "lodash";

//import data from "@/dataset";

const PhotographyPageContainer = styled.div`
  width: 100%;
`;
const AWSbase = "https://img-photography-site.s3.ap-southeast-1.amazonaws.com/";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledImg = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    filter: brightness(50%);
  }
`;

const ImgContainer = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover::after {
    content: attr(data-hover-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    text-align: center;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease-in-out forwards; /* Animation for fade-in effect */
  }
`;

const PhotographyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const SearchContainer = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 5rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledSortImgWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px; /* Set the desired height for the container */
  cursor: pointer;
  margin: 10px;
  /* 
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  } */

  overflow: hidden;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`;

const StyledImgSortContainer = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  align-items: center;

  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledSortImg = styled.img`
  /* width: 150px;
  height: 150px; */
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 10px;

  transition: transform 0.3s ease;

  /* ${StyledSortImgWrapper}:hover & {
    transform: scale(1.1);
  } */
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledSortText = styled.div`
  position: absolute;
  color: white;
  font-size: 24px;
`;

const StyledTick = styled.div`
  position: absolute;
  color: white;
  font-size: 60px;
  transition: opacity 0.3s ease;
`;

const SearchTextWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)``;

const StyledSelect = styled(Select)`
  margin: 0.8rem;
`;

const COUNTRY_LIST = [
  { value: "Bali", label: "Bali" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Taipei", label: "Taipei" },
  { value: "Singapore", label: "Singapore" },
  { value: "Thailand", label: "Thailand" },
  { value: "Bromo", label: "Bromo" },
  { value: "KualaLumpur", label: "Kuala Lumpur" },
  { value: "ThePhilippines", label: "The Philippines" },
  { value: "All", label: "All" },
];

const YEAR_LIST = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "All", label: "All" },
];

const SearchSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

const SelectWrapper = styled.div``;

function PhotographyPage() {
  const [countryData, setCountryData] = useState("All");
  const [yearData, setYearData] = useState("All");
  const [imgData, setImgData] = useState(null);
  const [imgDisplayData, setImgDisplayData] = useState(null);
  const [showTick, setShowTick] = useState({
    portrait: false,
    street: false,
    landscape: false,
    arch: false,
    sports: false,
    others: false,
  });

  console.log("imgData", imgData);
  const handleChangeCountry = (e) => {
    setCountryData(e);
  };

  const handleChangeYear = (e) => {
    setYearData(e);
  };

  const handleSearchClicked = () => {
    // let targetData = _.cloneDeep(imgDisplayData);
    // if (countryData !== "All") {
    //   targetData = targetData.filter((t) => t.country === countryData);
    // }
    // if (yearData !== "All") {
    //   targetData = targetData.filter((t) => t.year === yearData);
    // }
    // setImgDisplayData(targetData);

    let targetData = [];

    let objKeys = Object.keys(showTick);
    const regex = /\(([^)]+)\)/;

    // only get selected img type (ex, portrait, landscape)
    for (let i = 0; i < objKeys.length; i++) {
      let tempData = _.cloneDeep(imgData);
      if (showTick[objKeys[i]]) {
        targetData.push(
          ...tempData.filter((t) => {
            const matchType = t.match(regex)[1];
            if (matchType === objKeys[i]) {
              return true;
            } else {
              return false;
            }
          })
        );
      }
    }

    // when no styles has been ticked
    if (
      Object.values(showTick).filter((t) => t).length === 0 &&
      targetData.length === 0
    ) {
      let tempData = _.cloneDeep(imgData);
      targetData = tempData;
    }

    if (countryData !== "All") {
      targetData = targetData.filter((t) => t.includes(`_${countryData}_`));
    }

    if (yearData !== "All") {
      targetData = targetData.filter((t) => t.includes(`${yearData}_`));
    }

    setImgDisplayData(targetData);
  };

  //暫時用不到
  let handleGetAWSPrefix = (countryData, yearData) => {
    let prefix = "";
    if (yearData !== "All") {
      prefix += yearData;
    }

    if (countryData !== "All") {
      prefix += countryData;
    }
    // if (countryData === "All") {
    //   return "";
    // }

    // let prefix = "";
    // prefix += countryData + "/";
    // if (yearData !== "All") {
    //   prefix += yearData + "/";
    // }

    // console.log("prefix", prefix);
    // return prefix;
  };

  let params = {
    Bucket: "img-photography-site",
    //Delimiter: "/",
    //Prefix: handleGetAWSPrefix(countryData, yearData),
  };

  const client = new S3Client({
    region: "ap-southeast-1",
    credentials: {
      accessKeyId: "AKIAZN76OA6DZK33G6WA",
      secretAccessKey: "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",
    },
  });

  const handleClick = (type) => {
    setShowTick({ ...showTick, [type]: !showTick[type] });
  };

  useEffect(() => {
    async function test() {
      const s3 = new ListObjectsV2Command(params);
      let allKeys = [];
      const response = await client.send(s3).then((t) => {
        return t.Contents;
      });
      response.forEach(function (content) {
        allKeys.push(content.Key);
      });
      setImgData(allKeys);
    }
    test();
    return () => {};
  }, []);

  useEffect(() => {
    setImgDisplayData(imgData);
  }, [imgData]);

  return (
    <PhotographyPageContainer>
      <SearchContainer>
        <SearchTextWrapper>Sort by...</SearchTextWrapper>
        <StyledImgSortContainer>
          <StyledSortImgWrapper onClick={() => handleClick("portrait")}>
            <StyledSortImg
              data-src={AWSbase + "2024_Singapore_anish3(portrait).jpg"}
              //width={700}
              src={AWSbase + "2024_Singapore_anish3(portrait).jpg"}
            />
            {showTick["portrait"] && <Mask />}
            {showTick["portrait"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>portrait</StyledSortText>
            )}
          </StyledSortImgWrapper>
          <StyledSortImgWrapper onClick={() => handleClick("street")}>
            <StyledSortImg
              data-src={AWSbase + "2023_Singapore_busAtTampines(street).jpg"}
              //width={700}
              src={AWSbase + "2023_Singapore_busAtTampines(street).jpg"}
            />
            {showTick["street"] && <Mask />}
            {showTick["street"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>Street</StyledSortText>
            )}
          </StyledSortImgWrapper>
          <StyledSortImgWrapper onClick={() => handleClick("landscape")}>
            <StyledSortImg
              data-src={AWSbase + "2023_Bali_uluwatuSunset(landscape).jpg"}
              //width={700}
              src={AWSbase + "2023_Bali_uluwatuSunset(landscape).jpg"}
            />
            {showTick["landscape"] && <Mask />}
            {showTick["landscape"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>landscape</StyledSortText>
            )}
          </StyledSortImgWrapper>
          <StyledSortImgWrapper onClick={() => handleClick("arch")}>
            <StyledSortImg
              data-src={AWSbase + "2022_Taipei_chruchNeihu(arch).jpg"}
              //width={700}
              src={AWSbase + "2022_Taipei_chruchNeihu(arch).jpg"}
            />
            {showTick["arch"] && <Mask />}
            {showTick["arch"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>Architecture</StyledSortText>
            )}
          </StyledSortImgWrapper>
          <StyledSortImgWrapper onClick={() => handleClick("sports")}>
            <StyledSortImg
              data-src={
                AWSbase + "2024_Singapore_pasirRisParkBiker(sports).jpg"
              }
              //width={700}
              src={AWSbase + "2024_Singapore_pasirRisParkBiker(sports).jpg"}
            />
            {showTick["sports"] && <Mask />}
            {showTick["sports"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>Sports</StyledSortText>
            )}
          </StyledSortImgWrapper>
          <StyledSortImgWrapper onClick={() => handleClick("others")}>
            <StyledSortImg
              data-src={AWSbase + "2022_Taipei_trainAndElaine(others).jpg"}
              //width={700}
              src={AWSbase + "2022_Taipei_trainAndElaine(others).jpg"}
            />
            {showTick["others"] && <Mask />}
            {showTick["others"] ? (
              <StyledTick>&#10003;</StyledTick>
            ) : (
              <StyledSortText>Others</StyledSortText>
            )}
          </StyledSortImgWrapper>
        </StyledImgSortContainer>
        <SearchSelectWrapper>
          <SelectWrapper>
            <StyledSelect
              defaultValue="All"
              style={{ width: 180 }}
              placeholder="Select a destination"
              onChange={handleChangeCountry}
              options={COUNTRY_LIST}
            />
            <StyledSelect
              defaultValue="All"
              style={{ width: 100 }}
              placeholder="Select a year"
              onChange={handleChangeYear}
              options={YEAR_LIST}
            />
          </SelectWrapper>
          <StyledButton onClick={handleSearchClicked}>Search</StyledButton>
        </SearchSelectWrapper>
      </SearchContainer>
      <PhotographyWrapper>
        {imgDisplayData
          ? imgDisplayData.map((t, index) => (
              <ImgContainer
                key={index}
                // 暫時mute掉，因為跟preview text衝突
                // data-hover-text={`${t.desc + "\n"} ${t.country + ", " + t.year}`}
                // style={{ whiteSpace: "pre-line" }}
              >
                <StyledImg
                  data-src={AWSbase + t}
                  //width={1000}
                  src={AWSbase + t}
                />
              </ImgContainer>
            ))
          : null}
      </PhotographyWrapper>
    </PhotographyPageContainer>
  );
}

export default PhotographyPage;

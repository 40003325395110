import { Link } from "react-router-dom";
import styled from "styled-components";

const HomeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
`;

const TitleContainer = styled.div``;

const StyledLink = styled(Link)`
  margin: 10px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid black;
  padding: 10px;
  font-weight: bold;
  &:hover {
    background-color: #29dbdb;
  }
`;

const HeaderTitle = styled.div`
  font-size: 5rem;
  font-weight: bold;
  cursor: pointer;
  font-family: "Rubik Doodle Shadow";
`;

const LinkContainer = styled.div``;

function HomeHeader() {
  return (
    <HomeHeaderContainer>
      <TitleContainer>
        <Link to="/photography">
          <HeaderTitle>LIN TSE</HeaderTitle>
        </Link>
      </TitleContainer>
      {/* <LinkContainer>
        <StyledLink to="/aboutMe">About me</StyledLink>
      </LinkContainer> */}
    </HomeHeaderContainer>
  );
}

export default HomeHeader;

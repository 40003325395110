import styled from "styled-components";

const FooterContainer = styled.div`
  margin-top: auto;
  padding: 30px 10px 15px 10px;
`;

const Copyright = styled.div`
  text-align: center;
  font-weight: bold;
`;

function Footer() {
  return (
    <FooterContainer>
      <Copyright>©bywaterTravel made with love by Lin Tse.</Copyright>
    </FooterContainer>
  );
}

export default Footer;

import { Helmet } from "react-helmet";

function HelmetWrapper({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      {children}
    </>
  );
}

export default HelmetWrapper;
